<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="3">
            <v-card color="modal" class="my-0 py-0" style="border-radius: 20px">
              <v-card-text class="ma-0 pa-0">
                <v-list dense class="ma-0 pa-0" subheader>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title> Share My Contacts </v-list-item-title>
                      <v-list-item-subtitle
                        style="font-size: 11px"
                        class="text-wrap"
                      >
                        This will allow the other party to effectively manage
                        your shipment profiles
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch
                        color="blue"
                        v-model="company.shareContacts"
                        @click="saveRelationship()"
                      ></v-switch>
                    </v-list-item-action>
                  </v-list-item>
                 
                  <v-divider></v-divider>
                  <v-subheader
                    ><v-icon class="mr-2">person</v-icon> Account
                    Executive</v-subheader
                  >
                  <v-divider></v-divider>
                  <v-list-item
                    id="account-manager"
                    v-if="company.accountExecutive"
                    @click="
                      editAccountManager({
                        name: 'Account Executive',
                        value: 'accountExecutive',
                      })
                    "
                  >
                    <v-list-item-action>
                      <v-avatar size="32">
                        <v-img
                          v-if="company.accountExecutive.avatar"
                          contain
                          :src="company.accountExecutive.avatar"
                        ></v-img>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ company.accountExecutive.firstname }}
                        {{ company.accountExecutive.surname }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-if="company.accountExecutive.emailAddress"
                      >
                        <v-icon class="mr-1" small color="grey">email</v-icon>
                        {{ company.accountExecutive.emailAddress }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="company.accountExecutive.mobileNumber"
                      >
                        <v-icon class="mr-1" small color="grey">phone</v-icon>
                        {{ company.accountExecutive.mobileNumber }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-else
                    @click="
                      editAccountManager({
                        name: 'Account Executive',
                        value: 'accountExecutive',
                      })
                    "
                    id="account-manager"
                  >
                    <v-list-item-action>
                      <v-icon color="primary">add_circle_outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        >Add Account Executive</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-subheader
                    ><v-icon class="mr-2">account_circle</v-icon> Account
                    Manager</v-subheader
                  >
                  <v-divider></v-divider>
                  <v-list-item
                    id="account-manager"
                    v-if="company.accountManager"
                    @click="
                      editAccountManager({
                        name: 'Account Manager',
                        value: 'accountManager',
                      })
                    "
                  >
                    <v-list-item-action>
                      <v-avatar size="32">
                        <v-img
                          v-if="company.accountManager.avatar"
                          contain
                          :src="company.accountManager.avatar"
                        ></v-img>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ company.accountManager.firstname }}
                        {{ company.accountManager.surname }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-if="company.accountManager.emailAddress"
                      >
                        <v-icon class="mr-1" small color="grey">email</v-icon>
                        {{ company.accountManager.emailAddress }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="company.accountManager.mobileNumber"
                      >
                        <v-icon class="mr-1" small color="grey">phone</v-icon>
                        {{ company.accountManager.mobileNumber }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-else
                    @click="
                      editAccountManager({
                        name: 'Account Manager',
                        value: 'accountManager',
                      })
                    "
                    id="account-manager"
                  >
                    <v-list-item-action>
                      <v-icon color="primary">add_circle_outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Add Account Manager</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-subheader
                    ><v-icon class="mr-2">confirmation_number</v-icon> Booking
                    Coordinator(s)
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      icon
                      @click="
                        addRelationshipRole({
                          name: 'Booking Coordinator',
                          value: 'BOOKING_COORDINATOR',
                        })
                      "
                      ><v-icon>add_circle_outline</v-icon></v-btn
                    >
                  </v-subheader>
                  <v-divider></v-divider>
                  <div :key="userKey">
                    <div v-if="bookingCoordinators.length > 0">
                      <v-list-item
                        id="account-manager"
                        v-for="user in bookingCoordinators"
                        :key="user.id"
                      >
                        <v-list-item-action>
                          <v-avatar size="32">
                            <v-img
                              v-if="user.organisationUser.user.avatar"
                              contain
                              :src="user.organisationUser.user.avatar"
                            ></v-img>
                          </v-avatar>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ user.organisationUser.user.firstname }}
                            {{ user.organisationUser.user.surname }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            v-if="user.organisationUser.user.emailAddress"
                          >
                            <v-icon class="mr-1" small color="grey"
                              >email</v-icon
                            >
                            {{ user.organisationUser.user.emailAddress }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            v-if="user.organisationUser.user.mobileNumber"
                          >
                            <v-icon class="mr-1" small color="grey"
                              >phone</v-icon
                            >
                            {{ user.organisationUser.user.mobileNumber }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>

                    <v-list-item v-else id="account-manager">
                      <v-list-item-content class="text-center">
                        <span style="font-size: 12px; color: grey"
                          >No Booking Coordinators Listed</span
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </div>

                  <v-divider></v-divider>
                  <v-divider></v-divider>
                  <v-subheader
                    ><v-icon class="mr-2">article</v-icon> Documentation
                    Coordinator(s)
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      icon
                      @click="
                        addRelationshipRole({
                          name: 'Documentation Coordinator',
                          value: 'DOCUMENTATION_COORDINATOR',
                        })
                      "
                    >
                      <v-icon>add_circle_outline</v-icon></v-btn
                    >
                  </v-subheader>
                  <v-divider></v-divider>
                  <div>
                    <div v-if="documentationCoordinators.length > 0">
                      <v-list-item
                        id="account-manager"
                        v-for="user in documentationCoordinators"
                        :key="user.id"
                      >
                        <v-list-item-action>
                          <v-avatar size="32">
                            <v-img
                              v-if="user.organisationUser.user.avatar"
                              contain
                              :src="user.organisationUser.user.avatar"
                            ></v-img>
                          </v-avatar>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ user.organisationUser.user.firstname }}
                            {{ user.organisationUser.user.surname }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            v-if="user.organisationUser.user.emailAddress"
                          >
                            <v-icon class="mr-1" small color="grey"
                              >email</v-icon
                            >
                            {{ user.organisationUser.user.emailAddress }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            v-if="user.organisationUser.user.mobileNumber"
                          >
                            <v-icon class="mr-1" small color="grey"
                              >phone</v-icon
                            >
                            {{ user.organisationUser.user.mobileNumber }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>

                    <v-list-item v-else id="account-manager">
                      <v-list-item-content class="text-center">
                        <span style="font-size: 12px; color: grey"
                          >No Documentation Coordinators Listed</span
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </div>

                  <v-divider></v-divider>

                  <v-subheader
                    ><v-icon class="mr-2">groups</v-icon> Assigned Teams
                    <v-spacer></v-spacer>
                    <v-btn
                      id="edit-teams"
                      icon
                      color="primary"
                      @click="editTeams"
                      ><v-icon small>edit</v-icon></v-btn
                    ></v-subheader
                  >
                  <v-list dense>
                    <v-list-item
                      v-for="team in teams"
                      :key="team.id"
                      :style="{
                        'border-left': `5px solid ${team.team.colour}`,
                      }"
                      class="my-1"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ team.team.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle :key="refreshTeamCount">
                          {{ getTeamMemberCount(team.team.id) }} Member(s)
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="teams.length == 0">
                      <v-list-item-content class="text-center">
                        <span style="color: grey">No teams listed</span>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="9">
            <v-row>
              <v-col cols="12" sm="3" v-if="modules.length > 0">
                <v-card
                  color="modal"
                  class="my-0 py-0"
                  style="border-radius: 20px"
                  :loading="loadingSharedModules"
                >
                  <v-card-title>
                    <v-icon color="grey" class="mr-2">share</v-icon> Shareable
                    Modules
                  </v-card-title>
                  <v-card-text>
                    <v-list dense>
                      <v-list-item
                        v-for="moduleItem in modules"
                        :key="moduleItem.id"
                      >
                        <v-list-item-action>
                          <v-icon color="grey">fiber_manual_record</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            moduleItem.name
                          }}</v-list-item-title>
                          <v-list-item-subtitle style="font-size: 12px">{{
                            moduleItem.sourceModule.name
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-switch
                            color="primary"
                            v-model="sharedModules"
                            :value="moduleItem.id"
                            @change="saveSharedModule"
                          ></v-switch>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- User manager modal -->
    <v-dialog
      v-model="accountManagerModal"
      width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card :loading="loadingUsers">
        <v-toolbar flat color="transparent">
          <v-toolbar-title v-if="editUserType">
            <v-icon class="mr-2">person</v-icon>
            {{ editUserType.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="accountManagerModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <el-input
            suffix-icon="el-icon-search"
            v-model="userSearch"
            clearable
            placeholder="Search"
          >
          </el-input>
          <v-list dense style="max-height: 70vh; overflow-y: auto">
            <v-list-item
              v-for="user in filterOrgUsers"
              :key="user.id"
              @click="updateAccountManager(user)"
            >
              <v-list-item-action>
                <v-avatar
                  size="40"
                  :color="user.user.avatar ? 'white' : 'secondary'"
                >
                  <v-img
                    v-if="user.user.avatar"
                    contain
                    :src="user.user.avatar"
                  ></v-img>
                  <h2 v-else color="white">
                    {{ user.user.firstname.charAt(0) }}
                  </h2>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ user.user.firstname }} {{ user.user.surname }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="user.position">
                  <v-icon small color="secondary" class="mr-2">label</v-icon>
                  {{ user.position }}
                </v-list-item-subtitle>

                <v-list-item-subtitle v-if="user.user.emailAddress">
                  <v-icon small color="grey" class="mr-2">email</v-icon>
                  {{ user.user.emailAddress }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="user.user.mobileNumber">
                  <v-icon small color="grey" class="mr-2">phone</v-icon>
                  {{ user.user.mobileNumber }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Team Modal -->
    <v-dialog
      v-model="teamModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title
            ><v-icon class="mr-2">groups</v-icon>Assigned Teams</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon @click="saveTeams" color="primary" :loading="savingTeams"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn text @click="teamModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="team in allTeams"
              :key="team.id"
              :style="{ 'border-left': `5px solid ${team.colour}` }"
              class="my-1"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ team.name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-checkbox
                  color="primary"
                  v-model="selectedTeams"
                  :value="team.id"
                ></v-checkbox>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="roleManagerModal"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card :loading="loadingUserRole">
        <v-toolbar flat>
          <v-toolbar-title>
            {{ userItem ? userItem.title : "" }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              (roleManagerModal = false),
                (userItem = null),
                (roleUserSearch = null)
            "
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text>
          <el-input
            suffix-icon="el-icon-search"
            v-model="roleUserSearch"
            clearable
            placeholder="Search"
          >
          </el-input>
          <v-list dense style="max-height: 70vh; overflow-y: auto">
            <v-list-item
              v-for="user in filterRoleUsers"
              :key="user.id"
              @click="addRoleUser(user)"
            >
              <v-list-item-action>
                <v-avatar
                  size="40"
                  :color="user.user.avatar ? 'white' : 'secondary'"
                >
                  <v-img
                    v-if="user.user.avatar"
                    contain
                    :src="user.user.avatar"
                  ></v-img>
                  <h2 v-else color="white">
                    {{ user.user.firstname.charAt(0) }}
                  </h2>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ user.user.firstname }} {{ user.user.surname }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="user.position">
                  <v-icon small color="secondary" class="mr-2">label</v-icon>
                  {{ user.position }}
                </v-list-item-subtitle>

                <v-list-item-subtitle v-if="user.user.emailAddress">
                  <v-icon small color="grey" class="mr-2">email</v-icon>
                  {{ user.user.emailAddress }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="user.user.mobileNumber">
                  <v-icon small color="grey" class="mr-2">phone</v-icon>
                  {{ user.user.mobileNumber }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

   
  </div>
</template>
<script>
import * as axios from "axios";
import * as CurrencyCodes from "currency-codes";

export default {
  props: ["company", "teams"],
  
  data: () => ({
    accountManagerModal: false,
    allTeams: [],
    billingItems: [],
    caProductModal: false,
    currencies: CurrencyCodes.codes(),
    configuration: {},
    editAccountReference: false,
    editPaymentTerms: false,
    editUserType: null,
    financeChargeItems: [],
    headers: [
      {
        text: "Name",
        value: "user.firstname",
        align: "center",
      },
      {
        text: "Surname",
        value: "user.surname",
        align: "center",
      },
      {
        text: "Email",
        value: "user.emailAddress",
        align: "center",
      },
      {
        text: "Position",
        value: "position",
        align: "center",
      },
      {
        text: "Office",
        value: "organisationAddress.alias",
        align: "center",
      },
    ],
    loadingSetting: false,
    loadingSharedModules: false,
    loadingUsers: false,
    loadingUserRole: false,
    modules: [],
    refreshTeamCount: 2000,
    roleManagerModal: false,
    roleUserSearch: null,
    savingClientStatus: false,
    savingRelationship: false,
    savingSettings: false,
    savingTeams: false,
    selectedCountry: null,
    selectedTab: 0,
    selectedTeams: [],
    setting: [],
    sharedModules: [],
    teamModal: false,
    userItem: {},
    users: [],
    userKey: 1000,
    userSearch: null,
  }),
  watch: {
    "company.organisationRelationshipCountries": {
      immediate: true,
      handler(val) {
        if (val && val.length > 0) {
          this.selectedCountry = val[0].countryRegion.iso2;
          this.getSetting();
        }
      },
    },
    // 'company.id': {
    //     immediate: true,
    //     handler(val){
    //         if(val){
    //             this.getBookingTemplate()
    //         }
    //     }
    // },
    selectedTab(val) {
      this.getSetting();
    },
  },
  computed: {
    bookingCoordinators() {
      let users = this.company.organisationRelationshipUsers.filter(
        (x) => x.role == "BOOKING_COORDINATOR"
      );
      return users;
    },
    documentationCoordinators() {
      let users = this.company.organisationRelationshipUsers.filter(
        (x) => x.role == "DOCUMENTATION_COORDINATOR"
      );
      return users;
    },
    filterOrgUsers() {
      let result = this.users;
      if (this.userSearch) {
        result = result.filter(
          (x) =>
            x.user &&
            ((x.user.firstname &&
              x.user.firstname
                .toLowerCase()
                .includes(this.userSearch.toLowerCase())) ||
              (x.user.surname &&
                x.user.surname
                  .toLowerCase()
                  .includes(this.userSearch.toLowerCase())) ||
              (x.user.emailAddress &&
                x.user.emailAddress
                  .toLowerCase()
                  .includes(this.userSearch.toLowerCase())) ||
              (x.position &&
                x.position
                  .toLowerCase()
                  .includes(this.userSearch.toLowerCase())))
        );
      }
      return result;
    },
    filterRoleUsers() {
      let result = this.users;
      if (this.userItem) {
        let existingUsers = this.company.organisationRelationshipUsers
          .filter((x) => x.role == this.userItem.role)
          .map((x) => x.organisationUserId);
        result = result.filter((x) => !existingUsers.includes(x.id));
      }
      if (this.roleUserSearch) {
        result = result.filter(
          (x) =>
            x.user &&
            ((x.user.firstname &&
              x.user.firstname
                .toLowerCase()
                .includes(this.userSearch.toLowerCase())) ||
              (x.user.surname &&
                x.user.surname
                  .toLowerCase()
                  .includes(this.userSearch.toLowerCase())) ||
              (x.user.emailAddress &&
                x.user.emailAddress
                  .toLowerCase()
                  .includes(this.userSearch.toLowerCase())) ||
              (x.position &&
                x.position
                  .toLowerCase()
                  .includes(this.userSearch.toLowerCase())))
        );
      }
      return result;
    },
  },
  created() {
    this.getOrganisationUsers();
    this.getTeams();
    this.getShareableModules();
  },
  methods: {
    async addRoleUser(user) {
      this.loadingUserRole = true;
      let obj = {
        organisationUser: user,
        role: this.userItem.role,
        organisationRelationshipId: this.company.id,
        organisationUserId: user.id,
      };
      let result = await this.$API.addOrganisationRelationshipUser(obj);
      result.organisationUser = user;
      this.company.organisationRelationshipUsers.push(result);
      this.userKey++;
      this.roleManagerModal = false;
      this.loadingUserRole = false;
    },
    editAccountManager(type) {
      this.editUserType = type;
      this.accountManagerModal = true;
    },
    editRelationshipRole(type) {
      this.editUserType = type;
      this.roleManagerModal = true;
    },
   
    addRelationshipRole(type) {
      this.userItem = {
        title: type.name,
        role: type.value,
      };
      // this.editUserType = type
      this.roleManagerModal = true;
    },
    editTeams() {
      this.selectedTeams = this.teams.map((team) => team.teamId);
      this.teamModal = true;
    },
   
    async getOrganisationUsers() {
      this.loadingUsers = true;
      this.users = await this.$API.getOrganisationUsers();
      this.loadingUsers = false;
    },
    async getSetting() {
      this.loadingSetting = true;
      this.setting = await this.$API.getOrganisationRelationshipCountrySetting(
        this.company.id,
        this.selectedCountry
      );
      let selectedSetting = this.company.organisationRelationshipCountries.find(
        (x) => x.countryRegion.iso2 == this.selectedCountry
      );
      if (selectedSetting.url) {
        let configuration = await axios.get(selectedSetting.url);
        this.configuration = configuration.data;
      }
      this.loadingSetting = false;
    },
    async getShareableModules() {
      this.loadingSharedModules = true;
      this.sharedModules = await this.$API.getSharedModules(
        this.company.relatedOrganisationId
      );
      this.modules = await this.$API.getShareableModules();
      this.loadingSharedModules = false;
    },
    
    async saveSharedModule() {
      await this.$API.setSharedModule(
        this.company.relatedOrganisationId,
        this.sharedModules
      );
      this.$message.success("Successfully updated shared modules!");
    },
    async getTeams() {
      this.allTeams = await this.$API.getTeams();
      this.refreshTeamCount++;
    },
    getTeamMemberCount(id) {
      let result = this.allTeams.find((x) => x.id == id);
      return result ? result.userTeams.length : 0;
    },
    async saveRelationship() {
      this.savingRelationship = true;
      if (!this.company.isCustomer && !this.company.isSupplier) {
        this.company.isCustomer = true;
      }
      this.company.accountManagerId = this.company.accountManager
        ? this.company.accountManager.id
        : null;
      console.log(this.company)
      await this.$API.updateRelation(this.company);
      this.$message.success("Successfully Updated!");
      this.$emit("refresh", this.company);
      this.savingRelationship = false;
      this.editPaymentTerms = false;
      this.editAccountReference = false;
    },
    async saveTeams() {
      this.savingTeams = true;
      let create = this.selectedTeams.filter(
        (x) => !this.teams.map((y) => y.teamId).includes(x)
      );
      let remove = this.teams.filter(
        (x) => !this.selectedTeams.includes(x.teamId)
      );
      if (create.length > 0) {
        await this.$API.createRelationshipTeam({
          batch: create.map((x) => ({
            teamId: x,
            organisationRelationshipId: this.company.id,
          })),
        });
      }
      for (let j = 0; j < remove.length; j++) {
        let relationshipteamId = this.teams.find(
          (x) => x.teamId == remove[j].teamId
        ).id;
        await this.$API.updateRelationshipTeam({
          id: relationshipteamId,
          isActive: false,
          isDeleted: true,
        });
      }
      this.$emit("refreshTeams");
      this.savingTeams = false;
      this.teamModal = false;
    },
    async saveSettings() {
      this.savingSettings = true;
      let selectedSetting = this.company.organisationRelationshipCountries.find(
        (x) => x.countryRegion.iso2 == this.selectedCountry
      );
      await this.$API.saveOrganisationCountrySettings({
        id: selectedSetting.id,
        settings: this.configuration,
      });
      this.$message.success("Successfully updated settings!");
      this.savingSettings = false;
    },
    updateAccountManager(user) {
      this.company[this.editUserType.value] = user.user;
      this.company[this.editUserType.value + "Id"] = user.userId;
      this.$emit("refresh", this.company);
      this.saveRelationship();
      this.accountManagerModal = false;
    },
    async updateClientStatus(status) {
      this.savingClientStatus = true;
      this.company.clientStatus = status;
      await this.saveRelationship();
      // this.$message.success("Successfully Updated Client Status!");
      this.savingClientStatus = false;
    },
    // async updateContactShare()
  },
};
</script>